import React, { useEffect, useState, useImperativeHandle } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "../WorkSpaceItemList.module.css";
import { ItemValidationRule, Archiving, WorkSpaceUserName, Temporality } from "../../../model/ItemModel";
import { SetDraft, SetRelease,UndoDraft,SetArchiving } from "../../../services/Item/ItemServicesCRUD";
import { Download, GetD4SignStatus} from "../../../services/Item/ItemServicesGet";
import stylesList from "../../Style/List.module.css";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import WorkSpaceItemListFileComponentImage from "../WorkSpaceItemListFileComponentImage";
import ValidationRuleButton from "./ValidationRuleButton/ValidationRuleButton";
import { useUpload } from "../../../context/UploadProvider/useUpload";
import MenuCont from "../../../components/Menu/Menu"
import {EditFolderOpen} from '../PanelItens/EditFolder';
import { RenameConfig } from '../PanelItens/RenameComp';
import { ShareFileConfig } from "../PanelItens/ShareFile/ShareFileComp";
import { filesSelected } from "../WorkSpaceItemListFile";
import { MenuConfig } from "../../../components/Menu/MenuConfig";
import { ShrinkTitle } from "../../../components/ShrinkTitles/ShrinkTitles";
import { ClockField } from "./ClockField";
import {TagName} from "../../../model/Tags"
import Tags from "../Tags"
import TagsField from "./TagsField"
import {
  ResetTemporality
} from "../../../services/Item/ItemServicesCRUD";
import { NameField } from "./Components/NameField/NameField";
import { DescriptionField } from "./Components/DescriptionField/DescriptionField";
import ModalTemporalityComponent from "./ModalTemporalityComponent";
import { UseStatus } from "../../../components/Status/hooks/useStatus";
import Loupe from './Components/Loupe/Loupe';
import { WorkItemPropsTyped } from "./WorkItemProps/WorkItemProps";
import { ReplaceFile } from "./Components/ReplaceFile/ReplaceFile";
import { file, checkbox } from "../WorkSpaceItemListStyle/ColumnStandard";
import DialogUnduDraft from "./DialogUnduDraft/DialogUnduDraft";
import { TemporalityConfig } from "../PanelItens/TemporalityDocument";
import D4SignFilewView from "../Viwer/Signed/D4SignFilewView";
import DocumentDetail from "../../DocumentDetail/DocumentDetail";
import Status from "../../../components/Status/Status";
import { CopyFolderStructureDrawerParameters } from "../PanelItens/CopyFolderStructureDrawer";
import LoaderComp from '../../../components/Loader/LoaderComp';
import {returnByteWithNOtation} from "./ReturnByteWithNOtation"

export interface CanShowAlert {
  setIsCheckedExternal(set:boolean): void;
  getFilesSelected(): filesSelected;
}

const WorkSpaceItemListFileShrink: React.ForwardRefExoticComponent<WorkItemPropsTyped> =
React.forwardRef(({  workItemProps:{
  workspaceItem,
  setIsReleaze,
  setValidationRule,
  nameOrg,
  ModifiedBy,
  deleteItem,
  setFolderExternal,
  idWorkspace,
  nameWorkspace,
  setPopupError,
  setPopupOk,
  onclickRevision,
  onclikMoveOpen,
  layoutPendencies,
  refresh,
  renameUpdate,
  temporalityDocument,
  editFolderOpen,
  setShareFileConfig,
  permissionType,
  isAdmin,
  boxLayout,
  objectReference,
  onClickFlow,
  CheckboxItemList,
  handleMultiDrag,
  handleChange,
  onDrop,
  refViwer,
  idFolder,
  exibirViewer,
      isCurrentViewerItem, //ver
      setMenuConfigConst, //ver
  updateNameDescriptionByIndex,
  setTemporalityExteral,
  indexOfArray,
  batchWaiter,
  openDetailModal,
      batchRefresh, //ver
      selectedCheckbox, //ver
      currentBatchStatus, //ver
  updateTagName,
  notificationEmail,
  setWorkspace,
  setIsD4SignSignedConst,
  setCopyFolderStructureDrawerParametersExternal,
  currentFolder
}, isUpload}, ref) => {

    function setWorkspaceInternal(workSpaceUserName:WorkSpaceUserName)
    {
      if(setWorkspace != undefined)
        setWorkspace(workSpaceUserName, indexOfArray, isUpload);
    }

    function handleMultiDragInternal(ev: React.DragEvent<HTMLDivElement>): void {
      if(isChecked == false)
      {
        setIsChecked(true);
      }

      if(handleMultiDrag)
        handleMultiDrag(ev, getFilesSelectedInternal());
    }

    function onClickTag(){
      openPanelTag()
    }

      useImperativeHandle(
        ref,
          () => ({
              setIsCheckedExternal(set:boolean) {
                setIsChecked(set);
              },
              getFilesSelected() {
                return getFilesSelectedInternal();
              },
          }),
      ) 
      function getFilesSelectedInternal()
      {
        let select:filesSelected ={
          extension:workspaceItem.extension!,
          id:workspaceItem.id!, 
          img: <WorkSpaceItemListFileComponentImage extension={workspaceItem.extension} isFolder={workspaceItem.itemType === "folder" ? true : false} />,
          isFolder: workspaceItem.itemType === "folder",
          name:workspaceItem.name!,
          isRelease:workspaceItem.isRelease!,
          validationRule:workspaceItem.validationRule!,
        }
        return select;
      }

      const handleChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked( event.target.checked);
        if(handleChange != undefined)
        {
          handleChange(getFilesSelectedInternal(),event.target.checked)
        }
      }

      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const { t, i18n } = useTranslation();
      const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const Close = () => {
        setAnchorEl(null);
      };
      const profile = useProfile();
      const linkRef = React.useRef(null);

      const [isSet, setIsSet] = useState<boolean>(false);
      const [isHover, setisHover] = useState<boolean>(false);
      const [unvalidType, setUnvalidType] = useState<boolean>(false);

      const [isOPenModalD4Sign, setIsOPenModalD4Sign] =
        useState<boolean>(false);

      const [progress, setProgress] = useState<number>(0);
      const [respValidaionRule, setRespValidaionRule] = useState<boolean>(false);
      const [isValidateViwer, setIsValidateViwer] = useState<boolean>(false);
      const [isValidateReadViwer, setIsValidateReadViwer] = useState<boolean>(false);
      const [paintBlue, setPaintBlue] = useState<boolean>(false);
      const [archivingItem, { setTrue: setArchivingItemTrue, setFalse: setArchivingItemFalse }] = useBoolean(workspaceItem.archiving!);
      const upload = useUpload();
      const [isChecked, setIsChecked] = useState<boolean>(false);
      const [tagInternal, setTagInternal] = useState<TagName[]|undefined>(workspaceItem.tagName);
      const [isModalTemporalityOpen, { setTrue: showModalTemporality, setFalse: hideModalTemporality }] = useBoolean(false);
      const [isOpenTag, { setTrue: openPanelTag, setFalse: dismissPanelTag }] = useBoolean(false);

      const isReleaseConst: boolean = workspaceItem.isRelease == true;
      const currentStatus = UseStatus(isReleaseConst!, workspaceItem.validationRule, workspaceItem.itemType!, batchWaiter, workspaceItem.archiving, workspaceItem.expired, workspaceItem.isD4SignSigned);
      const idFile:string = workspaceItem.id?.toString() + "fileInput";
      const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
      const [isBoxLayoutShowed, setIsBoxLayoutShowed] = useState<boolean>(false);
      const [undoDraftEnabled, setUndoDraftEnable] = useState<boolean>(false);
      const [hideUndoDraftDialog, { toggle: toggleHideUndoDrafDialog }] = useBoolean(true);
      
      function closeDetail() {
        setIsDetailOpen(false);
      }

      useEffect(() => {
        if(workspaceItem.revisions){
          if(workspaceItem.revisions?.length > 1) {
            setUndoDraftEnable(true);
          } else {
            setUndoDraftEnable(false);
          }
        }
      }, [workspaceItem.revisions])
      
      

      const editFolder = () => {
        if(workspaceItem.name && editFolderOpen && workspaceItem.id)
        {
          let editFolderOpenVar : EditFolderOpen = {
            idMongo: workspaceItem.id,
            ownerId: workspaceItem.ownerId,
            ownerName: workspaceItem.ownerName,
            nameChanged: workspaceItem.name,
            sensitivity: workspaceItem.sensitivity,
            notificationEmail:notificationEmail!
          }
          editFolderOpen(editFolderOpenVar);
        }
      }

  const openCopyFolderStructureDrawer = () => {
    if (setCopyFolderStructureDrawerParametersExternal && workspaceItem.id && workspaceItem.name) {
      const copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters = {
        id: workspaceItem.id,
        name: workspaceItem.name,
      }
      setCopyFolderStructureDrawerParametersExternal(copyFolderStructureDrawerParameters);
    }
  }
    
      const renameExternal = () => {
        if (workspaceItem.id && workspaceItem.itemType) {
          let renameConfig: RenameConfig = {
            itemId: workspaceItem.id,
            nameChanged: workspaceItem.name!,
            extensionChanged: workspaceItem.extension,
            isFolder: workspaceItem.itemType != "file",
          };

          renameUpdate(renameConfig);
        }
      };


      const returnMenuConfig = (): MenuConfig => {
        const menuConfig: MenuConfig = {
          ExibirItemD4SignTrue:ExibirItemD4SignTrue,
          downloadFile:downloadFile,
          excluir:excluir,
          revision:revision,
          serRelease:serRelease,
          setShared: setShared,
          setDraft:verifyTemporalityToDraft,
          currentUserDraft:workspaceItem.currentUserDraft!,
          extension:workspaceItem.extension,
          isAdmin:isAdmin,
          isD4SignSignedConst:workspaceItem.isD4SignSigned,
          isReleaseConst:isReleaseConst,
          itemType:workspaceItem.itemType,
          layoutPendencies:layoutPendencies,
          ownerId:workspaceItem.ownerId,
          permissionType:permissionType,
          validatioRuleConst:workspaceItem.validationRule,
          idItem:workspaceItem.id!,
          nameItem: workspaceItem.name!,
          openViwer:false,
          nameOrg:nameOrg,
          idWorkspace:idWorkspace,
          nameWorkspace:nameWorkspace,
          searshName:"",
          deleteItem:deleteItem,
          onClickRevision:onclickRevision,
          moveOpen:moveOpen,
          editFolderOpen:editFolderOpen!,
          setEditFolderOpen:editFolder,
          setPopupOkMessage:setPopupOk,
          setPopupErroMessage:setPopupError,
          setArchiving:UpdateArchivingExt,
          archiving:archivingItem,
          rename: renameExternal,
          boxLayout: boxLayout,
          undoDraftIsOpen: workspaceItem.unduDraftIsOpen,
          undoDraft:undoDraft,
          temporalityDocument: validateDocumentExternal,
          existStorageArchive: workspaceItem.existStorageArchive,
          openCopyFolderStructureDrawer: openCopyFolderStructureDrawer,
          currentFolder: currentFolder
        }
        
        return menuConfig;
      }

      function setTemporalityInternal(newTemporality:Temporality | undefined)
      {
        if(setTemporalityExteral != undefined)
          setTemporalityExteral(indexOfArray, newTemporality, isUpload);
      }

      useEffect(() => {
        if (permissionType == 4) {
          setUnvalidType(true);
          
        } else {
          setUnvalidType(false);
        
          if (permissionType == 2) {
            setIsValidateViwer(true);
            setIsValidateReadViwer(false);
          } else if (permissionType == 1 || permissionType == 3) {
              setIsValidateViwer(false);
              setIsValidateReadViwer(false);
          } else {
            setIsValidateReadViwer(true);
            setIsValidateViwer(false);
          }
        }
      }, [
        workspaceItem.itemType,
        permissionType,
        i18n.language,
      ]);

      useEffect(() => {

        if(currentStatus === "Draft" && (workspaceItem.currentUserDraft == profile.id)){
          setPaintBlue(true);
        }
        else if(currentStatus !== "Draft" && currentStatus !== "Release" && currentStatus !== "---" && respValidaionRule){
          setPaintBlue(true);
        }
        else
        {
          setPaintBlue(false);
        }
  
        let orgAdmin: boolean = false;
        if (profile.organizations != undefined) {
          for (let organization of profile.organizations) {
            if (nameOrg == organization.internalName) {
              orgAdmin = true;
            }
          }
        }
  
  
        if (workspaceItem.validationRule != undefined && workspaceItem.validationRule.isTheUserResponsible != undefined) {
          setRespValidaionRule(workspaceItem.validationRule.isTheUserResponsible);
        }
        else {
          setRespValidaionRule(false);
        }
  
      }, [profile, workspaceItem.validationRule, isReleaseConst, respValidaionRule, workspaceItem.currentUserDraft]);

      const onDownloadProgress = (progressEvent: any) => {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      };
      
      function downloadFile() {
        if (workspaceItem.name) {
          Download(workspaceItem.id!, onDownloadProgress)
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", workspaceItem.name + "." + workspaceItem.extension);
              document.body.appendChild(link);

              link.click();
              link.parentNode?.removeChild(link);
              setPopupOk(true, t("DownloadSuccess.message"));
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.data &&
                typeof error.response.data === "string"
              ) {
                let blob = new Blob([error.response.data]);
                blob
                  .text()
                  .then((message) => {
                    setPopupError(true, message);
                  })
                  .catch(() => {
                    setPopupError(true, t("DownloadFileError.message"));
                  });
              } else {
                setPopupError(true, t("DownloadFileError.message"));
              }
            });
        }
      }


  function verifyTemporalityToDraft(){
    if(workspaceItem.itemTemporality != null){
      showModalTemporality()
    }
    else{
      setDraft()
    }
    }
    

   const setDraft  = () => {
    if(workspaceItem.id != undefined)
    {
      Close();
      setIsSet(true);
      SetDraft(workspaceItem.id)
      .then((message) =>{
        setIsD4SignSignedConst(undefined, false, indexOfArray, isUpload);
        setIsReleaze(false, indexOfArray, isUpload);
        setValidationRule(undefined, indexOfArray, isUpload);
      })
      .catch((error) =>{
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setPopupError(true, error.response.data);
        }
        else
        {
          setPopupError(true, t("DraftError.message"));
        }
      })
      .finally(() => {
        setIsSet(false);
      });
    }
  }

  function UpdateArchivingExt(){

    let archivingl: boolean = archivingItem == false;
    if(workspaceItem.id != undefined){
      let archivingConfig:Archiving = 
      {
        idItem: workspaceItem.id,
        archiving: archivingl
      };    
      SetArchiving(archivingConfig)
      .then(()=>{
          if(archivingl)
          {
            setArchivingItemTrue();
          }
          else
          {
            setArchivingItemFalse();
          }
      })
      .catch((error)=>{
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
          setPopupError(true, error.response.data);
        }
        else
        {
          setPopupError(true, t("ArcinvingError.message"));
        }
      })
    }
  }

      const serRelease = () => {
        if (workspaceItem.id != undefined) {
          Close();
          setIsSet(true);
          SetRelease(workspaceItem.id)
            .then((message) => {
              setIsReleaze(true, indexOfArray, isUpload);
            })
            .catch((error) => {
              if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setPopupError(true, error.response.data);
              }
              else
              {
                setPopupError(true, t("ReleaseError.message"));
              }
            })
            .finally(() => {
              setIsSet(false);
            });
        }
      };

      const onClickUndoDraft = () => {
        if (workspaceItem.id != undefined) {
          Close();
          setIsSet(true);
          toggleHideUndoDrafDialog()
          UndoDraft(workspaceItem.id)
            .then((message) => {
              setIsReleaze(true, indexOfArray, isUpload);
              setUndoDraftEnable(false)
              setPopupOk(true, "Draft desfeito")
            })
            .catch((error) => {
              if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setPopupError(true, error.response.data);
              }
              else
              {
                setPopupError(true, "Falha ao desfazer Draft");
              }
            })
            .finally(() => {
              setIsSet(false);
            });
        }
      };

      const undoDraft = () => {
        toggleHideUndoDrafDialog()
      }

      const setShared = () => {
        if (workspaceItem.id != undefined) {
          let shareFileConfig: ShareFileConfig = {
            itemId: workspaceItem.id,
          };
          setShareFileConfig(shareFileConfig);
        }
      };

      const excluir = () => {
        if (deleteItem && workspaceItem.id) {
          deleteItem(workspaceItem.id);
        }
      };

      const revision = () => {
        if (onclickRevision && workspaceItem.id) {
          onclickRevision(workspaceItem.id);
        }
      };

      const moveOpen = () => {
        if (onclikMoveOpen && workspaceItem.id) {
          onclikMoveOpen(workspaceItem.id);
        }
      };

      const ExibirItemD4SignTrue = () => {
        setIsOPenModalD4Sign(true);
      };


      
      const validateDocumentExternal = () => {
        if (workspaceItem.id && temporalityDocument) {
          let temporalityConfig: TemporalityConfig = {
            itemId: workspaceItem.id,
            setTemporalityInternal:setTemporalityInternal
          };
          temporalityDocument(temporalityConfig);
        }
      };

      const preventDefaultBehaviour = (
        event: React.DragEvent<HTMLDivElement>
      ) => {
        event.stopPropagation();
        event.preventDefault();
      };

      const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
        preventDefaultBehaviour(event);
        setisHover(true);
      };

      const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        preventDefaultBehaviour(event);
        setisHover(false);
      };

      function leave() {
        setisHover(false);
      }

      const refreshClick = (event: React.MouseEvent<HTMLElement>) => {
        if(refresh)
            refresh();
      };

      const onClickExibirViewer = () => {
        if(editFolderOpen && exibirViewer){
          
          exibirViewer(workspaceItem.id!, workspaceItem.name!, workspaceItem.extension!, returnMenuConfig())

        }
      };

      
      const reset = () =>
      {
          ResetTemporality(workspaceItem.id!)
          .then(() =>{
            setTemporalityInternal(undefined)
            setPopupOk(true, t("Reset.message"));
            setIsD4SignSignedConst(undefined, false, indexOfArray, isUpload);
            setIsReleaze(false, indexOfArray, isUpload)
            setValidationRule(undefined, indexOfArray, isUpload);
            closeModalTemporality()
            setDraft();
          })
          .catch((error) => {
              if( error.response && error.response.data && typeof error.response.data  === 'string'){
                 setPopupError(true,error.response.data);
              }
              else
              {
                setPopupError(true,t("ErroAoSalvar.message"));
              }
          })
          .finally(() => {
            //setWaitPanelResp(false)
          })
      }

      const closeModalTemporality = () => {
        hideModalTemporality();
      };

      return (
        <li
          className={`${archivingItem ? stylesList.disabledList : null} ${styles.borderGridbox}`}
          key={idWorkspace} 
          ref={objectReference}
        >
          <div
              className={styles.boxLayoutGrid}
              draggable={!unvalidType && layoutPendencies==false}
              onDragStart={!unvalidType ? handleMultiDragInternal : undefined}
              ref={refViwer}
            >
              <div id="first-row" className={styles.firstRowStyle}>
                <div className={styles.firstChildColumn}>
                  <div className={styles.firstItemsColumn }>
                    <div className={styles.fistItemsStylesColumn}
                      onMouseEnter={() => setIsBoxLayoutShowed(true)}
                      onMouseLeave={() => setIsBoxLayoutShowed(false)}
                    >
                      <div>
                        {handleChange != undefined &&(
                          <div>
                            <div
                              style={{
                                gridArea:checkbox
                              }}
                            >
                                <input 
                                  className={`${styles.checkboxContainer}`}
                                  style={{
                                    gridArea:checkbox
                                  }}
                                  type="checkbox"
                                  onChange={handleChangeInternal}
                                  value={workspaceItem.name!}
                                  id={workspaceItem.id}
                                  src={workspaceItem.extension! ? workspaceItem.extension! : "folder"}
                                  checked={isChecked}
                                />
                              </div>
                          </div>
                        )}
                      </div>
                      {!layoutPendencies && permissionType != undefined &&(
                      <div
                        style={{
                          gridArea: file
                        }}
                      >
                          <ReplaceFile 
                            currentUserDraft={workspaceItem.currentUserDraft}
                            idFile={idFile}
                            isReleaseConst={isReleaseConst}
                            itemType={workspaceItem.itemType!}
                            permissionType={permissionType}
                            profile={profile}
                            validationRuleConst={workspaceItem.validationRule}
                            idMongo={workspaceItem.id!}
                            isUpload={isUpload}
                            workspaceItem={workspaceItem}
                          />

                      </div>
                      ) }
                      <div>
                        <Loupe 
                          workspaceItem={workspaceItem}
                          downloadFile={downloadFile}
                          onClickExibirViewer={onClickExibirViewer}
                        />
                      </div>
                      <div style={{ marginRight: '16px' }}>
                        <WorkSpaceItemListFileComponentImage boxLayout={boxLayout} extension={workspaceItem.extension} isFolder={workspaceItem.itemType === "folder" ? true : false} />
                      </div>
                      
                      <div className={styles.firstItemsEllipses}>
                        <NameField
                          onDrop={onDrop}
                          extension={workspaceItem.extension!}
                          id={workspaceItem.id}
                          isHover={isHover}
                          isValidateReadViwer={isValidateReadViwer}
                          isValidateViwer={isValidateViwer}
                          itemType={workspaceItem.itemType!}
                          layoutPendencies={layoutPendencies}
                          leave={leave}
                          nameFile={workspaceItem.name}
                          onDragEnterAndOver={onDragEnterAndOver}
                          onDragLeave={onDragLeave}
                          progress={progress}
                          setFolderExternal={setFolderExternal}
                          unvalidType={unvalidType}
                          boxLayout={boxLayout}
                          updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                          indexOfArray={indexOfArray}
                          setPopupError={setPopupError}
                          setPopupOk={setPopupOk}
                          description={workspaceItem.description}
                          setWorkspace={setWorkspaceInternal}
                          openDetailModal={openDetailModal}
                          setIsDetailOpen={setIsDetailOpen}
                          isUpload={isUpload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.firstRowSecondPartRowStyle}>
                  <div className={styles.statusTagAdjust}>
                    <Status
                      release={isReleaseConst!}
                      type={workspaceItem.itemType!}
                      validationObject={workspaceItem.validationRule}
                      onClick={() => onClickFlow({ idMongo: workspaceItem.id!, nameFile: workspaceItem.name!, extensionFile: workspaceItem.extension! })}
                      batchWaiter={batchWaiter}
                      archiving={workspaceItem?.archiving}
                    />
                    {isSet && (
                      <LoaderComp/>
                    )}
                  </div>
                  <div className={styles.firstRowSecondPartChildColumn}>
                    <div className={styles.firstRowSecondPartItemsDiv}>
                      <div className={styles.deadLineStyles}>
                        <ClockField 
                          isReleaseConst={isReleaseConst}
                          timeDeadLine={workspaceItem.validationRule?.timeDeadline!}
                          validatioRule={workspaceItem.validationRule}
                          itemTemporality={workspaceItem.itemTemporality}
                        />
                      </div>
                      <div style={{
                        padding: '0px'
                      }}>
                        {editFolderOpen != undefined &&(
                          <MenuCont
                            menuConfig={{
                            ExibirItemD4SignTrue:ExibirItemD4SignTrue,
                            downloadFile:downloadFile,
                            excluir:excluir,
                            revision:revision,
                            serRelease:serRelease,
                            setShared: setShared,
                            setDraft:verifyTemporalityToDraft,
                            currentUserDraft:workspaceItem.currentUserDraft!,
                            extension:workspaceItem.extension,
                            isAdmin:isAdmin,
                            isD4SignSignedConst:workspaceItem.isD4SignSigned,
                            isReleaseConst:isReleaseConst,
                            itemType:workspaceItem.itemType,
                            layoutPendencies:layoutPendencies,
                            ownerId:workspaceItem.ownerId,
                            permissionType:permissionType,
                            validatioRuleConst:workspaceItem.validationRule,
                            idItem:workspaceItem.id!,
                            nameItem: workspaceItem.name!,
                            openViwer:false,
                            nameOrg:nameOrg,
                            idWorkspace:idWorkspace,
                            nameWorkspace:nameWorkspace,
                            searshName:"",
                            deleteItem:deleteItem,
                            onClickRevision:onclickRevision,
                            moveOpen:moveOpen,
                            editFolderOpen:editFolderOpen,
                            setEditFolderOpen:editFolder,
                            setPopupOkMessage:setPopupOk,
                            setPopupErroMessage:setPopupError,
                            setArchiving:UpdateArchivingExt,
                            archiving:archivingItem,
                            rename: renameExternal,
                            boxLayout: boxLayout,
                            undoDraftIsOpen: workspaceItem.unduDraftIsOpen,
                            undoDraft:undoDraft,
                            temporalityDocument: validateDocumentExternal,
                            existStorageArchive:workspaceItem.existStorageArchive,
                            openCopyFolderStructureDrawer: openCopyFolderStructureDrawer,
                            currentFolder: currentFolder
                          }}/>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.secondRowStyles}>
                <div>
                  <span className={styles.title} >
                    {t("Description.message")}
                  </span>
                </div>
                <div style={{ width: '100%' }}>
                  <DescriptionField 
                    indexOfArray={indexOfArray}
                    id={workspaceItem.id}
                    name={workspaceItem.name}
                    descriptionFile={workspaceItem.description}
                    itemType={workspaceItem.itemType}
                    layoutPendencies={false} 
                    setPopupError={setPopupError}
                    setPopupOk={setPopupOk}
                    isDetailPage={false}
                    updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                    isUpload={isUpload}
                  />
                </div>
              </div>
              <div id="third-row" className={styles.thirdRowStyles}>
                <div id="modified-by">
                  
                  <div>
                    <ShrinkTitle 
                      text="Tags"
                    />
                  </div>

                  <TagsField
                    boxLayout={boxLayout}
                    itemType={workspaceItem.itemType!}
                    onClickTag={onClickTag}
                    tagInternal={tagInternal}
                  />

                  <Tags
                    idMongo={workspaceItem.id!}
                    idWorkspace={idWorkspace}
                    setPopupError={setPopupError}
                    setPopupOk={setPopupOk}
                    tags={tagInternal}
                    dismissPanel={dismissPanelTag}
                    isOpen={isOpenTag}
                    name={workspaceItem.name!}
                    descricao={workspaceItem.description!}
                    updateTagName={updateTagName!}
                    indexOfArray={indexOfArray}
                    setTagInternal={setTagInternal}
                    isUpload={isUpload}
                  />
                </div>

                <div id="date-modified">
                  <div>
                    <ShrinkTitle 
                      text={t("Datadamodificacao.message")}
                    />
                  </div>
                  <div style={{ marginTop: '10px' }} className={styles.fontStyles}>
                    {workspaceItem.lastWriteDate == undefined || workspaceItem.lastWriteDate == "" ? (
                      <div className={styles.DateList}>---</div>
                        ) : (
                      <div className={styles.DateList}>
                        {moment(workspaceItem.lastWriteDate).format("DD/MM/YYYY")}
                        {""}
                      </div>
                    )}
                  </div>
                </div>
                <div id="file-size">
                  <div>
                    <ShrinkTitle 
                    text={t("Tamanhodoarquivo.message")}
                    />
                  </div>
                  <div style={{ marginTop: '10px' }} className={styles.fontStyles}>
                    {workspaceItem.itemType == "file" ? returnByteWithNOtation(workspaceItem.size) : '--'}
                  </div>
                </div>

                <div id="validation-rule">
                  <div>
                    <ShrinkTitle 
                      text="Validation Rule"
                    />
                  </div>
                  <div style={{marginTop: '10px'}}>
                      <ValidationRuleButton 
                        indexOfArray={indexOfArray}
                        isValidateReadViwer={isValidateReadViwer}
                        nameOrg={nameOrg}
                        workspaceItem={workspaceItem}
                        setPopupError={setPopupError}
                        setWorkspace={setWorkspace}
                        setPopupOk={setPopupOk}
                        idWorkspace={idWorkspace}
                        isUpload={isUpload}
                        unvalidType={unvalidType}
                        respValidationRule={respValidaionRule}
                        setValidationRule={setValidationRule}
                      />
                  </div>
                </div>
              </div>
          </div>

          <D4SignFilewView idItem={workspaceItem?.id!} isOpen={isOPenModalD4Sign!} setIsOpen={setIsOPenModalD4Sign} getLink={GetD4SignStatus} />

          <ModalTemporalityComponent
            closeModalTemporality={closeModalTemporality}
            isModalTemporalityOpen={isModalTemporalityOpen}
            reset={reset}
          />

          <DialogUnduDraft 
            hidden={hideUndoDraftDialog}
            onDismiss={toggleHideUndoDrafDialog}
            title={t("Item.undoDraft")}
            subText={t("ConfirmTheDiscardOfTheDraft.message")  + ` - ${workspaceItem.name}.${workspaceItem.extension}`}
            primaryButton={{onClick: onClickUndoDraft, text:t("Undo.message")}}
            secondaryButton={{onClick: toggleHideUndoDrafDialog, text:t("Cancelar.message")}}
          />

          <DocumentDetail
            isOpen={isDetailOpen}
            isOpenTag={isOpenTag}
            openPanelTag={openPanelTag}
            closeDetail={closeDetail}
            workItem={workspaceItem}
            returnMenuConfig={returnMenuConfig}
            status={currentStatus}
            setPopupError={setPopupError}
            setPopupOk={setPopupOk}
            setTagInternal={setTagInternal}
            tagInternal={tagInternal}
            isValidateReadViwer={isValidateReadViwer}
            itemTypeInternal={workspaceItem.itemType!}
            respValidationRule={respValidaionRule}
            unvalidType={unvalidType}
            boxLayout={boxLayout}
            onClickTag={onClickTag}
            isHover={isHover}
            isValidateViwer={isValidateViwer}
            leave={leave}
            onDragEnterAndOver={onDragEnterAndOver}
            onDragLeave={onDragLeave}
            progress={progress}
            setFolderExternal={setFolderExternal}
            itemType={workspaceItem.itemType!}
            updateTagName={updateTagName!}
            indexOfArray={indexOfArray}     
            parameter={undefined}
            updateNameDescriptionByIndex={updateNameDescriptionByIndex}
            nameOrg={nameOrg}
            setWorkspace={setWorkspace}
            idWorkspace={idWorkspace}
            setValidationRule={setValidationRule}
            isUpload={isUpload}
            currentFolder={currentFolder}
          />

        </li>
      );
    }
  );

export default WorkSpaceItemListFileShrink;
